<template>
  <div id="app"> 
    <transition :name='transitionName'>
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import WeixinJSBridge from "weixin-js-sdk";
export default {
    components:{
    },
    name: 'app',
    data(){
      return{
        transitionName:'',
      }
    },
    watch: {
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if(to.meta.index > from.meta.index){
        //设置动画名称
        this.transitionName = 'slide-left';
      }else{
        this.transitionName = 'slide-right';
      }
    },
    methods:{
      
    setting: function() {
      console.log(12321)
        if(typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
          this.handleFontSize();
        } else {
          if(document.addEventListener) {
            document.addEventListener("WeixinJSBridgeReady", this.handleFontSize(), false);
          } else if(document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", this.handleFontSize());
            document.attachEvent("onWeixinJSBridgeReady", this.handleFontSize());
          }
        }
        
    },
    handleFontSize() {
          // 设置网页字体为默认大小
          WeixinJSBridge.invoke('setFontSizeCallback', {
            'fontSize': 0
          });
          // 重写设置网页字体大小的事件
              WeixinJSBridge.on('menu:setfont', function() {
              WeixinJSBridge.invoke('setFontSizeCallback', {
              'fontSize': 0
            });
          });
        }
  },
  created(){
    this.setting();
  }
  }
};
</script>

<style >

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}
#app{
  height:100%;
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.van-toast{
  width:300px;
  min-height:120px
}
.van-toast__text{
  font-size: 35px
}
.van-icon{
  font-size: 40px
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
textarea{
  font-size: 25px
}
.van-ellipsis {
  font-size: 30px;
}
.van-picker__cancel,
.van-picker__confirm {
  font-size: 30px;
}
.van-picker__cancel, .van-picker__confirm{
  font-size: 30px
}
</style>
