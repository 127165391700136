import Vue from 'vue'
import App from './App.vue'
import "amfe-flexible"
import "normalize.css"
import router from './router';
import Vant from 'vant';
import 'vant/lib/index.css';
import wx from "weixin-js-sdk"
import vueEsign from 'vue-esign';
import VueClipboard from 'vue-clipboard2';
import { Calendar } from 'vant';
import { Toast } from 'vant';
import { Loading } from 'vant';
import 'vant/lib/index.less';
import BaiduMap from 'vue-baidu-map'
import { Area } from 'vant';
import { Dialog } from 'vant';
import VueLoadmore from 'vuejs-loadmore';

// 上拉加载
Vue.use(VueLoadmore);

Vue.use(Area);//省市区
Vue.use(BaiduMap, {
  ak: 'Ky9PtV5O0VGXDRse02IhO8T6ozxpKftm'  //  在此输入你自己的百度地图ak
})

Vue.use(Toast);
Vue.use(Loading);
Vue.use(Calendar);
// import TencentCaptcha from 'vue-tencent-captcha';
// Vue.use(TencentCaptcha);
Vue.use(VueClipboard);
Vue.use(vueEsign)
Vue.use(Dialog);
Vue.prototype.$wx = wx;
Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
